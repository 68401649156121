@import "styles/colors.scss";
@import "styles/mixins.scss";

.auth {
	.modal-box-body {
		width: 354px;

		h2 {
			@include font( "Gilroy", bold, 1.42em );
			text-align: center;
			margin-bottom: 12px;
		}

		> p {
			text-align: center;
			font-size: 1em;
		}
	}
} 

.auth-start {
	button {
		@include solitude-bg-box( 13px );
		@include flex( center, space-between );
		
		cursor: pointer;
		border: 0;
		width: 100%;
		
		font-size: 1.14em;
		font-weight: 700;

		strong {
			width: 20px;
		}
	}

	button[data-value=facebook] {
		background: #37509A;
		color: $white;

		svg * { fill: $white; }
	}

	button + button {
		margin-top: 12px;
	}

	button[data-value=google] {

		position: relative;

		#google-btn {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
	}
}

.auth-form {

	a {
		font-weight: bold;
		text-decoration: none;
	}

	.button {
		margin-top: 16px;
		width: 100%;
	}

	.checkbox {
		margin-bottom: 16px;
	}

	.checkbox-box {
		border: 1px solid rgba( $dark_blue, .6 );
	}
}

.auth-bottom {
	@include flex( center, space-between );
	margin-top: 15px;
	
	span {
		color: $chambray;
	}

	button {
		cursor: pointer;
		font-weight: bold;
		color: $dark_blue;
		border: 0;
		background: transparent;
	}
}

@media (max-width: 430px ) {
	.auth .modal-box-body {
		width: calc( 100vw - 30px - 24px - 6px );
	}
}

@media (max-width: 350px ) {
	.auth-bottom span {
		font-size: 12px;
	}
}