@import "styles/colors.scss";
@import "styles/mixins.scss";

.button {
	cursor: pointer;
	@include font( "Gilroy", 800, 1.14em );
	text-decoration: none;
	text-transform: uppercase;
	display: inline-block;
	text-align: center;

	border: 0;
	border-radius: 10px;
	padding: 13px 27px;

	background: $dark_blue;
	color: $white;
	
	transition: background .4s, color .4s;

	@media ( max-width: 1450px ) {
		font-size: 1em;
	}

	&:hover {
		background: $dark_blue + d6;
	}

	.loader {
		height: auto;

		.loader-box {
			width: 20px;
			height: 20px;
		}
	}
}

.button.yellow {
	background: $yellow;
	color: $dark_blue;

	&:hover {
		background: $chambray;
		color: $yellow;
	}
}

.button.small {
	font-size: 0.85em;
	padding: 11.5px 18px;
}

.button[disabled] {
	background: rgba( $dark_blue, .1 );
	color: rgba( $dark_blue, .8 );

	&:hover {
		background: rgba( $dark_blue, .1 );
		color: rgba( $dark_blue, .8 );
	}
}

.button.outline {
	background: none;
	color: $dark_blue;
	border: 1px solid $dark_blue;

	&:hover {
		background: rgba( $dark_blue, .1 );
	}
}

.button.transparent {
	background: none;
	color: $chambray;
}