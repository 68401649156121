@import "styles/colors.scss";
@import "styles/mixins.scss";

.options {
	font-size: 1.14em;

	@media (max-width: 1450px ) {
		font-size: 1em;
	}

	label {
		display: block;
		margin-bottom: 5px;
		color: $chambray;
	}
}

.options-row {
	@include row( -5px );
}

.options.on-dark-bg {

	label {
		color: $echo_blue;
	}

	.options-item {
		
		border: 1px solid $sapphire; 
		background: $sapphire;
		color: $echo_blue;

		&.chosen {
			border-color: $yellow;
			color: $white;
			@include svg-color( $yellow );
		}
	}
}

.options-item {
	@include flex( center );
	@include solitude-bg-box( 8px 12px, 9px );
	cursor: pointer;
	
	font-weight: 600;
	margin: 0 5px 9px;

	transition: background .4s, color .4s;

	> svg {
		display: none;
	}

	> span {
		@include circle( 18px, $dark_blue, true );
		@include font( "Benzin", "bold", 6.75px );
		text-transform: uppercase;
		color: $white;
		margin-right: 8px;
	}

	&.chosen {
		background: $dark_blue;
		color: $white;

		> span {
			background: $yellow;
			color: $dark_blue;
		}
	}
}

.options.square-view {

	font-size: 1em;

	.options-row {
		flex: auto;
		margin: 0 -8px;
	}

	.options-item {
		@include flex-column;
		align-items: center;
		text-align: center;

		max-height: 154px;
		max-width: 154px;
		flex: 1;

		padding: 10px 6px;
		margin: 0 8px 8px;

		> svg {
			display: block;
			margin: auto;
			max-height: 75%;
		}

		> span {
			display: none;
		}
	}

}

.options.tab-view {
	font-size: 1.28em;
	margin: 0;

	@media (max-width: 1450px ) {
		font-size: 1.07em;
	}

	.options-row {
		margin: 0;
	}

	.options-item {

		border: 1px solid $solitude;
		background: #fff;

		flex: 1 1;
		margin: 0;
		border-radius: 0;
		padding: 13px 15px;
		justify-content: center;

		&:first-of-type {
			border-radius: 10px 0 0 10px;
		}

		&:last-child {
			border-radius: 0 10px 10px 0;
		}

		&.chosen {
			background: $dark_blue;
		}
	}
}