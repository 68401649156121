@import "styles/colors.scss";
@import "styles/mixins.scss";

.logo {
	@include flex_centered;
	font-size: 19.6px;
	text-decoration: none;

	span, strong {
		@include font( "Benzin", bold );
	}

	span {
		color: $dark_blue;
	}

	strong {
		@include stroke-text;
	}

	> svg {
		margin-right: 13px;
	}
}