@import "styles/colors.scss";
@import "styles/mixins.scss";

* { 
	box-sizing: border-box;
}

img, svg {
	max-width: 100%;
	height: auto;
}

a {
	color: $dark_blue;
}

#root {
	overflow: hidden;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
	flex: auto;
}

input, textarea, select, button {
	font-family: "Gilroy", sans-serif;
	font-weight: 500;
}

body {
	font-size: 14px;
	font-family: "Gilroy", sans-serif;
	font-weight: 500;
	margin: 0;
	color: $dark_blue;
}

.has-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: $dark_blue $solitude;

	&::-webkit-scrollbar {
		width: 8px;
		height: 5px;
	}
	
	&::-webkit-scrollbar-track {
		background: $solitude;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: $dark_blue;
		outline: none;
	}
}


p {
	margin-top: 0;
	font-size: 1.14em;
	margin-bottom: 20px;
	color: $chambray;

	@media ( max-width: 1450px ) {
		font-size: 1em;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Benzin", sans-serif;
	margin-top: 0;
}

h1 {
	font-size: 4.14em;
	text-transform: uppercase;

	@media ( max-width: 1450px ) {
		font-size: 3.76em;
	}

	@media ( max-width: 1240px ) {
		font-size: 3em;
	}

	@media ( max-width: 800px ) {
		font-size: 2.28em;
	}

	@media ( max-width: 370px ) {
		font-size: 1.71em;
	}
}

.container {
	max-width: 1694px;
	padding: 0 15px;
	margin: 0 auto;
}

.leaflet-container .leaflet-control-attribution {
	opacity: 0;
}

.toast {

    position: fixed;
    left: calc(50% - 140px );
	z-index: 1100;

	display: block;
    width: 280px;

	border-radius: 6px;
	color: #fff;
	background: $dark_blue;
    padding: 8px 18px 8px 11px;

	font-weight: 600;
    transition: top .5s;

	&.success {
		background: #048304;
	}

	&.error {
		background: #cf0b0b;
	}

	a {
		cursor: pointer;
		position: absolute;
		top: 5px;
		right: 5px;
		color: #fff;
	}
}