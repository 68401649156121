@import "styles/colors.scss";
@import "styles/mixins.scss";

.header {
	position: relative;
	padding: 14px 0;
	margin-bottom: 36px;

	&::before, &::after {
		content: '';
		display: block;
		position: absolute;

		z-index: -1;
		top: -25vh;

		width: 50vw;
		height: 50vh;
		background: radial-gradient(50% 50% at 50% 50%, rgba(255, 214, 0, 0.3) 0%, rgba(255, 214, 0, 0) 100%);
	}

	&::before {
		left: -20vw;
	}

	&::after {
		right: -20vw;
	}

	.container {
		@include flex( center, space-between );
	}

	.main-menu {
		max-width: 870px;
		margin-left: 15px;
		flex: 1 1; 
	}

	.mobile-menu-button {
		display: none;
		margin-left: 15px;
	}

	.socials-box {
		display: none;
	}
}

.header-right {
	@include flex( center, flex-end );

	.widget {
		margin-left: 15px;
	}
}