@import "styles/colors.scss";
@import "styles/mixins.scss";

.modal {
	display: flex;
	width: 100%;
	height: 100%;
	
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1001;

	padding: 10px;
	background: rgba( 0, 24, 78, 0.4 );
}

.modal-box {
	@include white-bg-box( 40px 6px 24px 24px );
	
	margin: auto;
	position: relative;

	h3 {
		@include font( "Gilroy", 800, 1.42em );
		margin-bottom: 15px !important;
	}
}

.modal-box-head {
	@include flex( center );

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	.close-button {
		margin-left: auto;
	}
}

.modal-box-body {
	max-height: calc( 100vh - 30px - 40px - 24px );
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: 18px;
}

@media (max-width: 370px ) {
	.modal-box {
		padding: 40px 0px 18px 18px;
	}
}