@import "styles/colors.scss";
@import "styles/mixins.scss";

.footer-menu {
	@include row;

	a {
		flex: 0 0 33.3333%;
		text-decoration: none;

		@include font( "Benzin", bold );
	}
}