@import "styles/colors.scss";
@import "styles/mixins.scss";

.extra-tooltip {
	width: 25px;
	cursor: pointer;
	display: flex;
	width: 25px;
	min-width: 25px;
	height: 25px;
	background: $yellow;
	border-radius: 10px;

	svg {
		margin: auto !important;
	}
}

.extra-tooltip-items {
	list-style: none;
	margin: 0;
	padding: 0 10px;
}

.modal.extra-tooltip-modal {
	h3 {
		font-size: 1.42em !important;
		text-align: center;
	}
}