
@media ( max-width: 1450px ) {
	.where-options {
		font-size: 1em;
	}
}

@media ( max-width: 875px ) {
	.where-options {
		width: calc( 100vw - 30px - 20px - 20px );
		margin: 0;
	}

	.where-options-countries {
		margin: 0;

		> div:first-child {
			margin-left: 0;
		}
	}

	.where-options-country {
		flex: 1 1 100%;
		margin: 0;
	}
}