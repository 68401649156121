@import "styles/colors.scss";
@import "styles/mixins.scss";


.mobile-menu-button {
	cursor: pointer;
	background: none;
	border: 0;
	padding: 10px 0 10px 10px;
	width: 30px;
	height: 40px;

	span {
		display: block;
		background: $dark_blue;
		height: 2px;
		border-radius: 2px;
		width: 20px;
		margin: 5px 0;

		transition: transform .2s;
	}

	span:last-child {
		width: 15px;
		margin-left: 5px;
	}
}

.mobile-menu-button.open {

	span {
		position: relative;
	}

	span:first-child {
		transform: rotate(45deg);
  		top: 3px;
	}

	span:last-child {
		position: relative;
    	transform: rotate(-45deg);
    	width: 20px;
	    top: -4px;
    	right: 5px;
	}
}