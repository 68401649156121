@import "styles/colors.scss";
@import "styles/mixins.scss";

.checkbox {
	cursor: pointer;
	
	display: flex;
	margin-bottom: 10px;
}

.checkbox.on-dark-bg {
	.checkbox-box {
		background: $sapphire;
		border: 0;
	}

	.checkbox-label {
		color: $echo_blue;
	}
	
	&.checked .checkbox-label {
		color: $white;
	}
}

.checkbox.checked {
	.checkbox-box {
		background: $yellow;
		border-color: $yellow;
	}

	.checkbox-label {
		color: $dark_blue;
	}
}

.checkbox-box {
	width: 18px;
	min-width: 18px;
	height: 18px;

	border: 1px solid $solitude;
	margin-right: 11px;

	@include flex_centered;
	border-radius: 4px;

	background: $white;
	transition: background .4s;

	&.checked {
		background: $yellow;
	}
}

.checkbox-label {
	font-weight: 600;
	color: $chambray;
}