@import "styles/colors.scss";
@import "styles/mixins.scss";

.confirm-modal {
	.modal-box h3 {
		text-align: center;
	}
}

.confirm-modal-message {
	font-size: 1.07em;
	text-align: center;
	margin-bottom: 15px;
}

.confirm-modal-buttons {
	@include flex( center, space-between );

	.button {
		flex: 1 1;
		margin: 0 5px;
	}
}