@import "styles/colors.scss";
@import "styles/mixins.scss";

.people-counter {
	position: relative;
	@include dropdown;
}

.people-counter-main {
	@include search-panel-input;
}

.people-counter-popup {
	@include dropdown-popup;
	@include solitude-bg-box( 20px );

	width: 250px;

	.counter + .counter {
		margin-top: 18px;
	}
}