@import "styles/colors.scss";
@import "styles/mixins.scss";

.profile-layout {
	flex: auto;
	padding-bottom: 45px;

	h1 {
		@include page-title;
		margin-bottom: 25px;
		width: 100%;
	}
}

.profile-layout-main {
	display: flex;
}

.profile-layout-sidebar {

	flex: 0 0 398px;
	margin-right: 24px;
	
	transition: left .3s;

	button {
		cursor: pointer;
		display: none;
		background: $dark_blue;
		width: 15px;

		border: 0;
		padding: 0;
		border-radius: 0 10px 10px 0;

		position: absolute;
		right: -15px;
		top: 0;
		height: 100%;
	}
}