@import "styles/colors.scss";

@mixin font( $ff, $fw: normal, $fz: 1em ) {
	font-family: $ff;
	font-weight: $fw;
	font-size: $fz;
}

@mixin flex-column( $align: unset, $justify: unset ) {
	@include flex( $align, $justify, column );
}

@mixin flex( $align: unset, $justify: unset, $direction: row ) {
	display: flex;
	flex-direction: $direction;
	align-items: $align;
	justify-content: $justify;	
}

@mixin row( $margin: 0 ) {
	display: flex;
	flex-wrap: wrap;
	margin: 0 $margin;
}

@mixin flex-centered {
	@include flex( center, center );
}

@mixin clear-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

@mixin circle( $size, $bg: transparent, $centered: false ) {
	border-radius: 50%;
	width: $size;
	min-width: $size;
	height: $size;

	background: $bg;

	@if $centered {
		@include flex_centered;
		padding: 0;
		margin: 0;
		border: 0;
	}
}

@mixin square-button( $size, $br: 10px ) {

	@include flex-centered;

	cursor: pointer;
	padding: 0;
	border: 0;

	width: $size;
	min-width: $size;
	height: $size;

	border-radius: $br;
}

@mixin background( $bp: 50%, $bs: cover ) {
	background-position: $bp;
	background-size: $bs;
	background-repeat: no-repeat;
}

@mixin stroke-text {
	color: transparent;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: $dark_blue;
}

@mixin svg-color( $color ) {
	svg.fill { *:not(.not-fill) { fill: $color; }}
	svg.stroke { * { stroke: $color; }}
}

@mixin solitude-bg-box( $padding: 10px, $br: 10px, $border: false ) {
	background: $solitude;
	border-radius: $br;
	padding: $padding;

	@if $border {
		border: 1px solid $solitude;
	}
}

@mixin white-bg-box( $padding: 10px, $br: 10px, $border: true ) {
	background: $white;
	padding: $padding;
	border-radius: $br;

	@if $border {
		border: 1px solid $solitude;
	}
}

@mixin dropdown {
	&.label-on-top {
		margin-top: 23px;
		
		> div:first-child {
			> span {
				position: absolute;
				left: 0;
				bottom: calc( 100% + 5px );
			}

			> strong {
				margin-left: 0;
			}
		}
	}

	&.standard-colors {
		> div:first-child {
			background: $white;
			border: 1px solid $solitude;

			> span {
				color: $dark_blue;
			}
	
			> strong {
				color: $dark_blue;
			}
		}
	}

	&.on-dark-bg {
		> div:first-child {
			background: $sapphire;
			color: $echo_blue;
			
			> span {
				color: $echo_blue;
			}

			> strong {
				color: $white;
			}
		}
	}
}

@mixin search-panel-input {
	@include solitude-bg-box( 8px 15px );
	@include flex( center );
	overflow: hidden;

	cursor: pointer;
	min-height: 50px;
	width: 100%;

	> span {
		color: $chambray;
		margin-right: 6px;
		white-space: nowrap;
	}

	> strong {
		margin-left: 10px;
		font-size: 1.14em;
		white-space: nowrap;
	}

	> svg {
		margin-left: auto;
	}
}

@mixin dropdown-popup {
	position: absolute;
	z-index: 15;
	top: calc( 100% + 10px );
}

@mixin options-white-smaller {
	.options {
		font-size: 1em;

		label {
			color: $dark_blue;
			font-weight: 600;
		}
	}

	.options-item {
		background: $white;

		&.chosen {
			background: $dark_blue;
		}
	}
}

@mixin user-select-none {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
}

@mixin map-box( $br: 10px, $w: 100%, $h: auto ) {
	border-radius: $br;
	width: $w;
	height: $h;
	background: rgba( 0, 0, 0, .1 );
	overflow: hidden;
}

@mixin subheading {
	@include font( "Gilroy", bold, 1.71em );
	margin-bottom: 15px;

	@media ( max-width: 1450px ) {
		font-size: 1.28em;
	}
}

@mixin page-title {
	@include font( "Gilroy", 800, 2.71em );
	text-transform: none;
	margin-bottom: 16px;

	@media ( max-width: 1450px ) {
		font-size: 2.4em;
	}

	@media ( max-width: 1240px ) {
		font-size: 2.1em;
	}

	@media ( max-width: 800px ) {
		font-size: 1.8em;
	}

	@media ( max-width: 370px ) {
		font-size: 1.5em;
	}
}

@mixin active-link-underline( $top: auto, $bottom: auto ) {

	position: relative;
	text-decoration: none;

	&.active::before {
		content: "";
		display: block;
		height: 2px;
		width: 100%;
		background: $yellow;
		position: absolute;
		top: $top;
		bottom: $bottom;
		left: 0;
	}
}

@mixin yellow-mini-box( $br, $padding, $fz: 1em ) {

	background: $yellow;
	color: $dark_blue;
	font-weight: bold;
	border-radius: 8px;
	padding: $padding;
	font-size: $fz;
}