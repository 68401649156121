@import "styles/colors.scss";
@import "styles/mixins.scss";

.not-found-section {
	padding-bottom: 20px;
	
	h2 {
		text-transform: uppercase;
		font-size: 1.714em;
	}

	.search-panel {
		margin-top: 55px;
	}
}

.not-found-section-top {
	@include flex( center, space-between );

	div:first-child {
		margin-right: 30px;
	}
}

@media ( max-width: 800px ) {

	.not-found-section h2 {
		font-size: 1.42em;
	}

	.not-found-section-top {
		flex-wrap: wrap;
		margin-bottom: 30px;

		> div {
			width: 100%;
			text-align: center;

			&:first-child {
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
}