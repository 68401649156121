@import "styles/colors.scss";
@import "styles/mixins.scss";

@mixin input-flex-basis( $basis ) {
	.search-panel .search-panel-main {
		.dropdown, 
		.date-range,
		.people-counter,
		.button {
			flex: 0 0 $basis;
			max-width: $basis;
			margin: 5px 0;
		}
	}
}

@media ( max-width: 1726px ) {
	@include input-flex-basis( calc(( 100% - 20px ) / 3 ));
}

@media ( max-width: 1100px ) {
	@include input-flex-basis( calc(( 100% - 10px ) / 2 ));
}

@media ( max-width: 890px ) {

	.search-panel-top {
		.transport-checkboxes {
			.checkbox {
				min-width: auto;
				flex: 1 1;

				&:last-child {
					margin-right: 0;
				}

				&-label {
					@include font( "Gilroy", bold, 1em );
				}
			}
		}
	}
}

@media ( max-width: 850px ) {
	@include input-flex-basis( 100% );

	.search-panel-main {
		border-radius: 0 0 8px 8px;
	}

	.search-panel .search-panel-main .button {
		margin-top: 20px;
		margin-bottom: 0;
	}
}

@media ( max-width: 600px ) {
	.search-panel-top {
		.transport-checkboxes {
			.checkbox {
				.checkbox-box {
					display: none;
				}

				.checkbox-label {
					margin: 0;

					svg {
						margin-right: 10px;
					}
				}
			}
		}
	}
}

@media ( max-width: 490px ) {
	.search-panel-top {
		.transport-checkboxes {
			.checkbox {

				height: 50px;

				.checkbox-label {
					font-size: 10px;
				}
			}
		}
	}
}