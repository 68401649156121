@import "styles/colors.scss";
@import "styles/mixins.scss";

.where-options {
	width: 773px;
	margin: 0 15px;
	font-size: 1.07em;

	@include options-white-smaller;
}

.where-options-countries {
	@include row( -20px );
	padding-top: 10px;
	overflow-y: auto;
	max-height: 60vh;

	> div:first-child {
		font-weight: 600;
		width: 100%;
		margin-left: 20px;
	}
}

.where-options-country {
	margin: 0 20px;
	flex: 0 0 348px;
	padding-top: 6px;
	border-bottom: 1px solid rgba( $dark_blue, .1 );

	.checkbox {
		@include flex( center );
		margin-bottom: 0;
		padding: 10px 0;
	}

	.checkbox.checked .checkbox-label > span {
		background: $yellow;
		color: $dark_blue;
	}

	.checkbox-label {
		@include flex( center );
		flex: auto;

		> span {
			@include circle( 18px, $dark_blue, true );
			color: $white;
			margin-left: auto;
		}
	}
}