@import "styles/colors.scss";
@import "styles/mixins.scss";

.offer-price {

	span {
		display: block;
		font-size: 0.85em;
		text-decoration: line-through;
		color: $chambray;
	}

	strong {
		display: block;
		font-weight: 800;
		font-size: 2.14em;

		@media ( max-width: 1450px ) {
			font-size: 1.71em;
		}
	}
}