
.loader {
	display: flex;
	height: 200px;
}

.loader-box {
	width: 49px;	
	height: 49px;

	margin: auto;

	animation: rotate 1500ms linear infinite;
	-o-animation: rotate 1500ms linear infinite;
	-ms-animation: rotate 1500ms linear infinite;
	-webkit-animation: rotate 1500ms linear infinite;
	-moz-animation: rotate 1500ms linear infinite;
}

@keyframes rotate {
	100% {
	  transform: rotate(360deg);
	}
}

@-o-keyframes rotate {
	100% {
	  -o-transform: rotate(360deg);
	}
}

@-ms-keyframes rotate {
	100% {
	  -ms-transform: rotate(360deg);
	}
}

@-webkit-keyframes rotate {
	100% {
	  -webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes rotate {
	100% {
	  -moz-transform: rotate(360deg);
	}
}