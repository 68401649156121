@import "styles/colors.scss";
@import "styles/mixins.scss";

.time {
	font-weight: 700;
	color: $chambray;

	@include flex( center );

	svg {
		margin-right: 6px;
	}
	
}