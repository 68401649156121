@import "styles/colors.scss";
@import "styles/mixins.scss";

.language-switcher {
	.widget-popup {

		@include flex-column;

		width: 40px;
		border-radius: 0px 0px 19.5px 19.5px;
		padding: 8px 2px;

		a {
			@include circle( 20px, transparent, true );
			margin: auto;
		}

		a + a {
			margin-top: 15px;
		}
	}	
}
