@import "styles/colors.scss";
@import "styles/mixins.scss";

.form-input {

	margin-bottom: 20px;

	label {
		font-size: 1em;
		margin-bottom: 5px;
		display: block;
	}

	input, textarea {
		width: 100%;
		min-height: 46px;
		border: 1px solid $solitude;
		border-radius: 10px;
		font-size: 1em;
		padding: 10px 15px;
		color: $dark_blue;
	}

	textarea {
		resize: none;
		height: 70px;
	}
}

.form-input.on-dark-bg {
	
	label {
		color: $echo_blue;
	}

	input, textarea {
		border: 0;
		background: $sapphire;
		color: $white;

		&::placeholder {
			color: $echo_blue;
		}
	}
}