@import "styles/colors.scss";
@import "styles/mixins.scss";

.pagination {
	@include flex( flex-end, flex-end );
	margin: 40px -5px 30px;

	span {
		width: 25px;
		text-align: center;
	}

	button {
		@include square-button( 25px, 5px );
		margin: 0 5px;
		color: $chambray;

		font-weight: 600;
		font-size: 1.14em;
	}

	button.prev {
		background: $solitude;
	}

	button.active {
		color: $dark_blue;
		background: rgba( $dark_blue, .8 );
		color: $white;
	}

	button.next {
		background: $dark_blue;
	}
}