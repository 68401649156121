@import "styles/colors.scss";
@import "styles/mixins.scss";

.user-widget {
	
	.profile-menu {
		border: 0;
		border-radius: 0;
		margin: 0;
		width: 287px;
	}
}

@media (max-width: 525px) {
	.user-widget {
		.profile-menu {
			width: 240px;
			font-size: 1em;
		}
	}
}