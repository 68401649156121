@import "styles/colors.scss";
@import "styles/mixins.scss";

.date-range {
	position: relative;
	@include options-white-smaller;
	@include dropdown;
}

.date-range-main {
	@include search-panel-input;

	> a {
		margin-left: auto;
	}

	a + svg {
		margin-left: 10px;
	}
}

.date-range-popup {
	@include dropdown-popup;
	@include solitude-bg-box( 20px 20px 11px );

	width: 535px;
}

.date-range-calendars {
	@include flex( flex-start, space-between );
	
	position: relative;
	padding: 16px 0 20px;


	.arrows {
		position: absolute;
		width: 100%;
		justify-content: space-between;
		margin: 0;
		z-index: 5;

		button {
			@include square-button( 20px, 5px );
			background: $white;
			margin: 0;
		}
	}
}