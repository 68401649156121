@import "styles/colors.scss";
@import "styles/mixins.scss";

.notification {

	cursor: pointer;
	text-decoration: none;

	display: inline-block;
	width: 100%;

	padding: 20px 10px;
	font-size: 1.28em;
	font-weight: 700;

	border-bottom: 1px solid $solitude;

	@media (max-width: 1450px) {
		font-size: 1.07em;
	}

	.time {
		margin-top: 13px;
		font-size: 11px;
	}
}