@import "styles/colors.scss";
@import "styles/mixins.scss";

.main-menu {

	@include flex( center, space-between );

	a {
		font-weight: 600;
		margin: 0 5px;
		
		@include active-link-underline( calc( 100% + 6px ) )
	}
}