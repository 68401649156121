@import "styles/colors.scss";
@import "styles/mixins.scss";

.notification-widget {

	.notification {
		width: 284px;
		font-size: 1em;
		padding: 12px 16px;

		&:last-of-type {
			border: 0;
		}

		&:hover {
			background: $solitude;
		}
	}

	.button {
		margin: 12px 16px 12px auto;
		width: 180px;
		display: block;
	}
}