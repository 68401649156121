@import "styles/colors.scss";
@import "styles/mixins.scss";

.dropdown {
	position: relative;
	@include dropdown;
}

.dropdown.disabled {
	.dropdown-main {
		opacity: .5;
	}
}

.dropdown-main {
	@include search-panel-input;
}

.dropdown-chosen {
	cursor: default;

	@include flex( center );
	font-weight: 600;
	font-size: 1.14em;
	
	margin-right: 4px;
}

.dropdown-chosen-label {
	color: $white;
	background: $dark_blue;
	height: 32px;
	border-radius: 5px 0 0 5px;
	padding: 6px 13px 6px 9px;
	white-space: nowrap;
}

.dropdown-chosen-close {
	@include flex_centered;
	cursor: pointer;
	background: $yellow;
	width: 32px;
	min-width: 32px;
	height: 32px;
	border-radius: 0 5px 5px 0;
}

.dropdown-popup {
	@include solitude-bg-box( 13px 10px );
	@include dropdown-popup;

	> button {
		cursor: pointer;
		border: 0;
		padding: 0;
		margin: 0;
		background: none;

		position: absolute;
		top: 5px;
		right: 5px;
		width: 10px;
	}
}

.dropdown-search {
	width: calc( 100% - 10px ); 
	margin-bottom: 15px;

	border: 2px solid $yellow;
	border-radius: 10px;
	padding: 10px 15px;
	height: 45px;

	background: transparent;
}


.dropdown-options {
	@include row;
	max-height: 60vh;
	overflow-y: auto;

	width: 810px;
	font-size: 1.07em;
	font-weight: 600;
}

.dropdown-options-item {
	flex: 0 0 227px;
	margin: 0 20px 10px;
	border-bottom: 1px solid rgba( $dark_blue, .1 );

	.checkbox {
		@include flex( center );
		margin-bottom: 0;
		padding: 10px 0;
	}

	.checkbox-box {
		order: 1;
		margin-right: 0;
		margin-left: auto;
	}

	.checkbox-label {
		@include flex( center );
		img, svg { 
			width: 20px;
			max-width: 20px;
			margin-right: 11px; 
		}
	}
}