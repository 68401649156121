@import "styles/colors.scss";
@import "styles/mixins.scss";

.counter {
	@include flex( center );
	font-weight: 600;
	font-size: 1.14em;
	user-select: none;

	label {
		margin-right: auto;
	}

	strong {
		margin: 0 10px;
		min-width: 16.5px;
		text-align: center;
	}

	button {
		cursor: pointer;
		@include circle( 20px, $white, true );
	}

	button:last-child {
		background: $yellow;
	}
}