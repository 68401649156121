@import "styles/colors.scss";
@import "styles/mixins.scss";

.profile-menu {

	@include white-bg-box(0);

	width: 100%;
	font-size: 1.14em;
	overflow: hidden;

	@media (max-width: 1450px ) {
		font-size: 1.07em;
	}

	a {
		@include flex( center );
		padding: 12px 17px;
		text-decoration: none;
		color: $chambray;

		svg {
			margin-right: 11px;
		}

		&.active {
			color: $dark_blue;
			background: $solitude;
			font-weight: 600;

			svg * {
				stroke: $dark_blue;
			}
		}

		&:hover {
			background: rgba( $dark_blue, .02 );
		}
	}
}