@import "styles/colors.scss";
@import "styles/mixins.scss";

.arrows {
	@include flex( center );
	margin: 0 -5px;

	button {
		margin: 0 5px;
		@include square-button( 40px );

		svg {
			max-width: 50%;
		}
	}
}

.arrows.round {
	button {
		border-radius: 50%;
		width: 50px;
		height: 50px;
		min-width: 50px;

		&:first-child {
			background: $dark_blue;
			@include svg-color( #fff );
		}

		&:last-child {
			background: $yellow;
		}

		@media ( max-width: 1200px ) {
			min-width: 40px;
			width: 40px;
			height: 40px;
		}
	}
}