
@media ( max-width: 1550px ) {
	.profile-layout-sidebar {
		flex: 0 0 300px;
	}
}

@media ( max-width: 1265px ) {

	.profile-layout-main {
		padding-left: 6px;
	}

	.profile-layout-sidebar {
		position: absolute;
		left: -300px;
		width: 300px;
		z-index: 999;

		button {
			display: block;
		}

		&.open {
			left: 0;

			button {
				svg {
					transform: rotate( 180deg );
				}
			}
		}

		.profile-menu {
			border-radius: 10px 0 0 10px;
		}
	}
}