
@media ( max-width: 1600px) {
	
	.footer-contact a {
		display: block;
		
		& + a {
			margin: 0;
		}
	}
}

@media ( max-width: 1440px) {
	.footer {
		.container {
			flex-wrap: wrap;
		}
	}

	.footer-copyright {
		order: 1;
		width: 100%;
		margin: 0 auto 35px;
		min-height: auto;
		text-align: center;

		> div {
			margin-top: 9px;
		}
	}

	.footer-menu {
		order: 2;
	}

	.footer-contact {
		order: 3;
	}

	.footer-socials {
		order: 4;
	}
}

@media ( max-width: 1080px) {

	.footer-menu, 
	.footer-contact,
	.footer-socials {
		width: 100%;
		text-align: center;
		margin-left: 0;
	}

	.footer-menu,
	.footer-contact {
		margin-bottom: 40px;
	}

	.footer-menu {
		a {
			flex: 1 1 100%;
			text-align: center;
			margin-bottom: 11px !important;
		}
	}

	.footer-socials .socials {
		justify-content: center;
	}
}