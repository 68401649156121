@import "styles/colors.scss";
@import "styles/mixins.scss";

.search-panel.preload {

	&::before, &::after {
		content: "";
		display: block;
	}

	&::before {
		height: 40px;
		width: 855px;
		border-radius: 10px 10px 0 0;
		background: $dark_blue;
	}

	&::after {
		width: 100%;
		height: 70px;
		max-height: 74px;
		background: #fff;
		border: 1px solid $solitude;
		border-radius: 0 10px 10px 10px;
	}
}

.search-panel-main {
	@include flex( center, space-between );
	@include white-bg-box( 10px, 0 10px 10px 10px );

	max-width: calc(100vw - 30px);
	flex-wrap: wrap;

	.date-range,
	.dropdown {
		&:first-child,
		&:nth-child(2),
		&:nth-child(3) {
			flex: 0 0 275px;
			max-width: 275px;
		}
	}

	.dropdown, 
	.date-range,
	.people-counter,
	.button {
		flex: 1 1 250px;
		max-width: 250px;
		margin: 0 5px;
	} 

	> .button {
		height: 50px;
	}
}