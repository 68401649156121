@import "styles/colors.scss";
@import "styles/mixins.scss";

.transport-checkboxes,
.transport-checkboxes-item {
	@include flex( center );
}

.transport-checkboxes .checkbox {

	min-width: 275px;
	align-items: center;

	padding: 10px;
	margin: 0 10px 0 0;
	border-radius: 10px 10px 0 0;

	background: $dark_blue;
	color: $white;

	&:first-child {
		min-width: 285px;
	}

	&.checked {
		background: $yellow;

		.checkbox-label {
			@include svg-color( $dark_blue );
			color: $dark_blue;
		}

		.checkbox-box {
			background: $dark_blue;
			@include svg-color( $yellow );
		}
	}

	.checkbox-box {
		order: 1;
		margin: 0 0 0 auto;
		background: $sapphire;
		border: 0;
	}

	.checkbox-label {
		@include flex( center );
		@include font( "Benzin", bold, 1.07em );
		@include svg-color( $white );

		color: $white;
		margin-right: 15px;

		@media (max-width: 1450px) {
			font-size: 0.9em;
		}

		svg {
			min-width: 20px;
			margin-right: 15px;
		}
	}
}