@import "styles/colors.scss";
@import "styles/mixins.scss";

.form-password {

	margin-bottom: 20px;

	label {
		font-size: 1em;
		margin-bottom: 5px;
		display: block;
	}
}

.form-password-main {
	@include flex( center );
	border: 1px solid $solitude;
	min-height: 46px;
	border-radius: 10px;

	input {
		flex: auto;
		padding: 13px 15px;
		background: transparent;
		border: 0;
		font-size: 1em;
		color: $dark_blue;
		border-radius: 10px 0 0 10px;

		&:focus {
			outline: none;
		}
	}

	> div {
		@include flex-centered;
		@include user-select-none;

		min-width: 44px;

		cursor: pointer;
		padding: 13px;
	}
}