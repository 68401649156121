@font-face {
	font-family: "Benzin";
	src: url( "../fonts/Benzin\ Regular.ttf" );
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Benzin";
	src: url( "../fonts/Benzin\ Bold.ttf" );
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Benzin";
	src: url( "../fonts/Benzin\ Semibold.ttf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Benzin";
	src: url( "../fonts/Benzin\ Medium.ttf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Benzin";
	src: url( "../fonts/Benzin\ Extra\ Bold.ttf");
	font-weight: 800;
	font-style: normal;
}

/* GILROY */

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Black.ttf");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Extrabold.ttf");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-ExtraboldItalic.ttf");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Bold.ttf");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-BoldItalic.ttf");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Semibold.ttf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-SemiboldItalic.ttf");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Medium.ttf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-MediumItalic.ttf");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-RegularItalic.ttf");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Light.ttf");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-LightItalic.ttf");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-UltraLight.ttf");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Thin.ttf");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-ThinItalic.ttf");
	font-weight: 100;
	font-style: italic;
}