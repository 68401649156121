@media (max-width: 585px ) {
	.date-range-popup {
		width: 290px;
		padding: 10px;
	}

	.date-range-calendars {
		.calendar {
			margin: auto;

			&:last-child {
				display: none;
			}
		}
	}
}