@import "styles/colors.scss";
@import "styles/mixins.scss";

.offer-title-place {
	
	h3 {
		@include font( "Gilroy", 700, 1.28em );
		margin-bottom: 10px;
	}

	span {
		font-weight: 600;
		color: $chambray;
	}

	.extra-tooltip {
		margin-top: 3px;
	}
}