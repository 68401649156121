@import "styles/colors.scss";
@import "styles/mixins.scss";

.favorite-offer {
	display: flex;
	width: 517px;
	padding: 16px;
	text-decoration: none;

	&:hover {
		background: $solitude;
	}

	.thumbnail {
		flex: 0 0 169px;
		height: 109px;
		padding: 6px;
	}


	.offer-price strong {
		font-size: 26px;
	}

	.offer-price {
		margin-top: auto;
	}
}

.favorite-offer-main {
	@include flex-column;
	flex: 0 0 191px;
	margin: 0 15px 0 20px;

	.offer-title-place {
		margin: auto 0; 
		
		h3 {
			font-size: 1.14em;
			margin-bottom: 5px;
		}
	}
}