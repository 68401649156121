@import "styles/colors.scss";
@import "styles/mixins.scss";

.footer {
	background: $dark_blue;
	color: $white;
	padding: 30px 0;
	min-height: 130px;
	
	.container {
		@include flex( center, space-between );
	}

	a {
		color: $white;
	}

	.logo {
		span {
			color: $white;
		}

		strong {
			-webkit-text-stroke-color: $white;
		}
	}

	.footer-menu {
		a {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				margin-bottom: 30px;
			}
		}
	}
}

.footer-copyright,
.footer-contact,
.footer-socials {
	@include flex-column( '', space-between );
	min-height: 70px;
	margin-left: 20px;
}

.footer-copyright {
	> div {
		color: rgba( $white, .7 );
	}
}

.footer-contact {
	a {
		font-weight: 600;
		font-size: 1.14em;
	}

	a + a {
		margin-left: 30px;
	}
}

.footer-socials .socials {
	a {
		min-width: 29px;
		width: 29px;
		height: 29px;

		background: rgba( $white, .1 );
		svg.fill * { fill: $white; }
	}

	li:first-child {
		a {
			background: $yellow;
			svg.fill * { fill: $dark_blue; }
		}
	}
}