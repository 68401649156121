@import "styles/colors.scss";
@import "styles/mixins.scss";


.calendar {
	width: 222px;
}

.calendar-head {
	position: relative;
	color: $dark_blue;
	text-align: center;
	font-weight: 600;
	margin-bottom: 18px;
	height: 20px;

	.arrows {
		position: absolute;
		width: 100%;
		justify-content: space-between;
		margin: 0;

		button {
			@include square-button( 20px, 5px );
			background: $white;
			margin: 0;
		}
	}
}

.calendar-day-names > div,
.calendar-days > div {
	flex: 0 0 calc( 100% / 7 );
}

.calendar-day-names {
	@include flex;
	margin-bottom: 10px;

	> div {
		padding: 0 3.5px;
		text-align: center;
		color: $chambray;
	}
}

.calendar-days {
	@include row;
}

.calendar-day {
	padding: 3.5px;

	span {
		cursor: pointer;

		display: inline-block;
		text-align: center;
		line-height: 24px;

		width: 24px;
		height: 24px;
		border-radius: 5px;
		background: $white;
		color: rgba( $chambray, .7 );
		font-weight: 600;
	}
}

.calendar-day.selected {
	span {
		background: $yellow;
		color: $dark_blue;
	}
}

.calendar-day.day-within-range {
	span {
		background: #fff7cc;
		color: #333e3e;
	}
}