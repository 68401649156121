@import "styles/colors.scss";
@import "styles/mixins.scss";

.widget {
	position: relative;
}

.widget-button {	
	@include circle( 40px, $white, true );

	cursor: pointer;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: contain;
	}

	span {
		position: absolute;
		@include circle( 13px, $white );
		top: -2px;
		right: 0;

		&::before {
			content: '';
			display: block;
			@include circle( 9px, $yellow );
			position: absolute;
			top: 2px;
			right: 2px;
		}
	}

	&.active {
		background: $yellow;
	}
}

.widget-popup {

	position: absolute;
	z-index: 10;

	top: calc( 100% + 8px );
	right: 0 !important;
	left: auto !important;

	border: 1px solid $solitude;
	border-top: 3px solid $yellow;
	background: $white;
	border-radius: 0 0 10px 10px;
	overflow: hidden;
}