@import "styles/colors.scss";
@import "styles/mixins.scss";

.socials {
	@include flex( center );
	@include clear-list;
	margin: 0 -6px;

	a {
		@include circle( 39px, $solitude, true );
		text-decoration: none;
		margin: 0 6px;

		svg {
			max-width: 45%;
		}
	}
}