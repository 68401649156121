@import "styles/colors.scss";
@import "styles/mixins.scss";

.categories-options {
	@include row;
	width: 802px;

	font-size: 1.07em;
	font-weight: 600;
}

.categories-options-box {
	flex: 0 0 227px;
	margin: 0 20px;
}

.categories-options-category {
	cursor: pointer;

	@include flex( center );
	padding: 10px 0;
	border-bottom: 1px solid rgba( $dark_blue, .1 );

	> div {
		margin-left: auto;

		.checkbox {
			margin: 0 0 0 auto;
	
			.checkbox-box {
				margin: 0;
			}
		}	
	}


	> img,
	> svg {
		max-width: 20px;
		width: 20px;
		margin-right: 11px;
	}
}

.categories-options-subcategories {
	padding-top: 10px;
	padding-left: 25px;
}

@media (max-width: 1450px ) {
	.categories-options {
		font-size: 1em;
	}
}

@media (max-width: 875px ) {
	.categories-options {
		width: calc( 100vw - 30px - 20px - 20px );
	}

	.categories-options-box {
		flex: 1 1 100%;
		margin: 0;
	}
}