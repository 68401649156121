@import "styles/colors.scss";
@import "styles/mixins.scss";

@media ( max-width: 1180px ) {
	.header {

		&::before,
		&::after {
			width: 65vw;
		}
			
		.mobile-menu-button {
			display: block;
		}

		.main-menu {
			display: none;
		}
	}


	.header.mobile-menu-open {
		position: fixed;
		z-index: 1001;
		height: 100%;
		width: 100%;

		background: #fff;

		.main-menu {
			display: block;
			position: fixed;
			max-width: none;
			width: 100%;
			top: calc( 54px + 40px );
			left: 0;
			margin: 0;
			padding: 0 15px;

			font-size: 1.14em;

			a {
				display: block;
				margin: 0 0 5px 0;
				padding: 10px 0;
				border-bottom: 1px solid $solitude;
			}

			a.active::before {
				top: 100%;
			}
		}

		.socials-box {
			position: fixed;
			bottom: 0;
			left: 0;

			display: block;
			width: 100%;
			padding: 0 15px 20px;

			h4 {
				text-align: center;
				margin-bottom: 13px;
			}

			.socials {
				justify-content: center;

				a {
					min-width: 30px;
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}

@media (min-width: 800px) {
	.header-right {
		min-width: 220px;
	}
}

@media ( max-width: 800px ) {
	.header {
		.favorites-widget,
		.notification-widget {
			display: none;
		}
	}
}

@media ( max-width: 375px ) {
	.header .logo {
		font-size: 15px;
	}

	.header-right {
		.mobile-menu-button,
		.widget {
			margin-left: 10px;
		}
	}
}